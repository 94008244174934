const data = [{
   "_id": {
     "$oid": "65f6e265f2c1a29d9340537e"
   },
   "id": "1",
   "team1": "Chennai Super Kings",
   "team2": "Royal Challengers Bangalore",
   "toss": " CSK won the toss and elected to bat first",
   "stadium": "MA Chidambaram Stadium, Chennai",
   "totalRunsTeam1": "209/4",
   "totalRunsTeam2": "164/6",
   "result": "CSK won by 45 runs",
   "battingTeam1": [
     {
       "id": "1",
       "player": "Rachin Ravindra",
       "runs": 27,
       "balls": 26,
       "fours": 4,
       "sixes": 1,
       "strikeRate": 103.85
     },
     {
       "id": "2",
       "player": "Ruturaj Gaikwad",
       "runs": 80,
       "balls": 56,
       "fours": 7,
       "sixes": 5,
       "strikeRate": 142.85
     },
     {
       "id": "10",
       "player": "Ajinkya Rahane",
       "runs": 14,
       "balls": 6,
       "fours": 3,
       "sixes": 0,
       "strikeRate": 233.33
     },
     {
       "id": "9",
       "player": "Shivam Dube",
       "runs": 53,
       "balls": 29,
       "fours": 4,
       "sixes": 4,
       "strikeRate": 182.76
     },
     {
       "id": "0",
       "player": "Mahendra Singh Dhoni",
       "runs": 20,
       "balls": 7,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 285.71
     },
     {
       "id": "3",
       "player": "Ravindra Jadeja",
       "runs": 0,
       "balls": 0,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     }
   ],
   "bowlingTeam1": [
     {
       "id": "30",
       "player": "Akash Deep",
       "overs": 4,
       "maidens": 0,
       "runs_": 31,
       "wickets": 0,
       "economy": 7.75
     },
     {
       "id": "28",
       "player": "Alzari Joseph",
       "overs": 4,
       "maidens": 0,
       "runs_": 34,
       "wickets": 1,
       "economy": 8.5
     },
     {
       "id": "26",
       "player": "Glenn Maxwell",
       "overs": 3,
       "maidens": 0,
       "runs_": 46,
       "wickets": 0,
       "economy": 15.33
     },
     {
       "id": "27",
       "player": "Vijaykumar Vyshak",
       "overs": 4,
       "maidens": 0,
       "runs_": 51,
       "wickets": 0,
       "economy": 12.75
     },
     {
       "id": "24",
       "player": "Mohammed Siraj",
       "overs": 4,
       "maidens": 0,
       "runs_": 29,
       "wickets": 0,
       "economy": 7.25
     },
     {
       "id": "32",
       "player": "Cameron Green",
       "overs": 1,
       "maidens": 0,
       "runs_": 11,
       "wickets": 0,
       "economy": 11
     }
   ],
   "extrasTeam1": 15,
   "battingTeam2": [
     {
       "id": "23",
       "player": "Virat Kohli",
       "runs": 53,
       "balls": 42,
       "fours": 1,
       "sixes": 3,
       "strikeRate": 126.19
     },
     {
       "id": "22",
       "player": "Faf du Plessis",
       "runs": 26,
       "balls": 18,
       "fours": 5,
       "sixes": 0,
       "strikeRate": 144.44
     },
     {
       "id": "32",
       "player": "Cameron Green",
       "runs": 5,
       "balls": 8,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 62.5
     },
     {
       "id": "29",
       "player": "Rajat Patidar",
       "runs": 15,
       "balls": 11,
       "fours": 0,
       "sixes": 1,
       "strikeRate": 136.36
     },
     {
       "id": "26",
       "player": "Glenn Maxwell",
       "runs": 22,
       "balls": 15,
       "fours": 0,
       "sixes": 2,
       "strikeRate": 146.67
     },
     {
       "id": "25",
       "player": "Mahipal Lomror",
       "runs": 4,
       "balls": 6,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 66.67
     },
     {
       "id": "31",
       "player": "Dinesh Karthik",
       "runs": 26,
       "balls": 18,
       "fours": 1,
       "sixes": 1,
       "strikeRate": 144.44
     },
     {
       "id": "27",
       "player": "Vijaykumar Vyshak",
       "runs": 2,
       "balls": 2,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 100
     }
   ],
   "bowlingTeam2": [
     {
       "id": "4",
       "player": "Matheesha Pathirana",
       "overs": 4,
       "maidens": 0,
       "runs_": 31,
       "wickets": 0,
       "economy": 7.75
     },
     {
       "id": "7",
       "player": "Deepak Chahar",
       "overs": 4,
       "maidens": 0,
       "runs_": 36,
       "wickets": 2,
       "economy": 9
     },
     {
       "id": "8",
       "player": "Tushar Deshpande",
       "overs": 3,
       "maidens": 0,
       "runs_": 26,
       "wickets": 1,
       "economy": 8.67
     },
     {
       "id": "5",
       "player": "Moeen Ali",
       "overs": 4,
       "maidens": 0,
       "runs_": 22,
       "wickets": 1,
       "economy": 5.5
     },
     {
       "id": "3",
       "player": "Ravindra Jadeja",
       "overs": 4,
       "maidens": 0,
       "runs_": 25,
       "wickets": 2,
       "economy": 6.25
     },
     {
       "id": "6",
       "player": "Shardul Thakur",
       "overs": 1,
       "maidens": 0,
       "runs_": 17,
       "wickets": 0,
       "economy": 17
     }
   ],
   "extrasTeam2": 11,
   "fallOfWicketsTeam1": [],
   "fallOfWicketsTeam2": [],
   "__v": 0,
   "team1flag": "https://i.pinimg.com/564x/ea/40/fd/ea40fd13fa7d46618c5567146f07ba41.jpg",
   "team2flag": "https://logotyp.us/file/royal-challengers.svg",
   "commentary": "The stage is set for a high-scoring encounter! The Chennai Super Kings boast a strong batting lineup, known to thrive at their home ground, the M.A. Chidambaram Stadium. Keep an eye on players who are currently in top form, they could be poised for big scores. Royal Challengers Bangalore will be looking to contain the CSK batsmen. Their bowling attack is known for its variety. The pitch at Chepauk is known to be batting-friendly. Expect some big hits and explosive batting displays",
   "battingFirst": "Chennai Super Kings",
   "bowlingFirst": "Royal Challengers Bangalore"
 },
 {
   "_id": {
     "$oid": "65f7a5bbfc9ad86b03323649"
   },
   "id": "2",
   "team1": "Lucknow Super Giants",
   "team2": "Delhi Capitals",
   "toss": "LSG won the toss and elected to bat first",
   "team1flag": "https://i.pinimg.com/564x/42/92/9f/42929f323d5f435c7838c8d7ab26f40b.jpg",
   "team2flag": "https://logotyp.us/file/delhi-capitals.svg",
   "commentary": "The Lucknow pitch is known for its batting-friendly qualities. Expect to see some explosive batting displays from both sides. Batsmen who can time the ball well are likely to score big runs. Both bowling attacks will need to adapt their strategies to the pitch conditions. Spinners might find some assistance later in the innings, while pacers who can swing the new ball or bowl with good pace could be crucial upfront. The team that executes their plans better across all departments - batting, bowling, and fielding - will be in a strong position to win",
   "stadium": "Ekana Cricket Stadium,Lucknow",
   "totalRunsTeam1": "161/8",
   "totalRunsTeam2": "134/9",
   "result": "LSG won by 27 runs",
   "battingTeam1": [
     {
       "id": "46",
       "player": "KL Rahul",
       "runs": 30,
       "balls": 25,
       "fours": 5,
       "sixes": 0,
       "strikeRate": 120
     },
     {
       "id": "44",
       "player": "Quinton de Kock",
       "runs": 31,
       "balls": 23,
       "fours": 3,
       "sixes": 1,
       "strikeRate": 134.78
     },
     {
       "id": "47",
       "player": "Devdutt Padikkal",
       "runs": 27,
       "balls": 24,
       "fours": 3,
       "sixes": 0,
       "strikeRate": 112.5
     },
     {
       "id": "50",
       "player": "Nicholas Pooran",
       "runs": 28,
       "balls": 25,
       "fours": 3,
       "sixes": 1,
       "strikeRate": 112
     },
     {
       "id": "45",
       "player": "Marcus Stoinis",
       "runs": 2,
       "balls": 3,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 66.67
     },
     {
       "id": "52",
       "player": "Krunal Pandya",
       "runs": 1,
       "balls": 3,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 33.33
     },
     {
       "id": "48",
       "player": "Ayush Badoni",
       "runs": 27,
       "balls": 11,
       "fours": 3,
       "sixes": 2,
       "strikeRate": 245.45
     },
     {
       "id": "51",
       "player": "Shivam Mavi",
       "runs": 0,
       "balls": 1,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     },
     {
       "id": "53",
       "player": "Naveen-ul-Haq",
       "runs": 2,
       "balls": 5,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 40
     }
   ],
   "bowlingTeam1": [
     {
       "id": "67",
       "player": "Anrich Nortje",
       "overs": 4,
       "maidens": 0,
       "runs_": 27,
       "wickets": 2,
       "economy": 6.75
     },
     {
       "id": "68",
       "player": "Axar Patel",
       "overs": 4,
       "maidens": 0,
       "runs_": 32,
       "wickets": 1,
       "economy": 8
     },
     {
       "id": "69",
       "player": "Mukesh Kumar",
       "overs": 4,
       "maidens": 0,
       "runs_": 29,
       "wickets": 2,
       "economy": 7.25
     },
     {
       "id": "76",
       "player": "Khaleel Ahmed",
       "overs": 4,
       "maidens": 0,
       "runs_": 44,
       "wickets": 1,
       "economy": 11
     },
     {
       "id": "73",
       "player": "Kuldeep Yadav",
       "overs": 3,
       "maidens": 0,
       "runs_": 12,
       "wickets": 1,
       "economy": 4
     },
     {
       "id": "71",
       "player": "Mitchell Marsh",
       "overs": 1,
       "maidens": 0,
       "runs_": 11,
       "wickets": 0,
       "economy": 11
     }
   ],
   "extrasTeam1": 13,
   "battingTeam2": [
     {
       "id": "72",
       "player": "David Warner",
       "runs": 28,
       "balls": 19,
       "fours": 3,
       "sixes": 1,
       "strikeRate": 147.37
     },
     {
       "id": "74",
       "player": "Prithvi Shaw",
       "runs": 0,
       "balls": 3,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     },
     {
       "id": "71",
       "player": "Mitchell Marsh",
       "runs": 29,
       "balls": 30,
       "fours": 1,
       "sixes": 2,
       "strikeRate": 96.67
     },
     {
       "id": "66",
       "player": "Rishabh Pant",
       "runs": 18,
       "balls": 12,
       "fours": 2,
       "sixes": 1,
       "strikeRate": 150
     },
     {
       "id": "75",
       "player": "Tristian Stubbs",
       "runs": 18,
       "balls": 18,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 100
     },
     {
       "id": "70",
       "player": "Yash Dhull",
       "runs": 14,
       "balls": 18,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 77.78
     },
     {
       "id": "68",
       "player": "Axar Patel",
       "runs": 10,
       "balls": 7,
       "fours": 0,
       "sixes": 1,
       "strikeRate": 142.86
     },
     {
       "id": "69",
       "player": "Mukesh Kumar",
       "runs": 2,
       "balls": 3,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 66.67
     },
     {
       "id": "76",
       "player": "Khaleel Ahmed",
       "runs": 0,
       "balls": 1,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     },
     {
       "id": "73",
       "player": "Kuldeep Yadav",
       "runs": 6,
       "balls": 7,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 85.71
     },
     {
       "id": "67",
       "player": "Anrich Nortje",
       "runs": 1,
       "balls": 2,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 50
     }
   ],
   "bowlingTeam2": [
     {
       "id": "53",
       "player": "Naveen-ul-Haq",
       "overs": 4,
       "maidens": 0,
       "runs_": 25,
       "wickets": 2,
       "economy": 6.25
     },
     {
       "id": "54",
       "player": "Mohsin Khan",
       "overs": 4,
       "maidens": 0,
       "runs_": 35,
       "wickets": 3,
       "economy": 8.75
     },
     {
       "id": "51",
       "player": "Shivam Mavi",
       "overs": 4,
       "maidens": 0,
       "runs_": 26,
       "wickets": 0,
       "economy": 6.5
     },
     {
       "id": "49",
       "player": "Ravi Bishnoi",
       "overs": 4,
       "maidens": 0,
       "runs_": 18,
       "wickets": 3,
       "economy": 4.5
     },
     {
       "id": "52",
       "player": "Krunal Pandya",
       "overs": 4,
       "maidens": 0,
       "runs_": 27,
       "wickets": 1,
       "economy": 6.75
     }
   ],
   "extrasTeam2": 8,
   "fallOfWicketsTeam1": [],
   "fallOfWicketsTeam2": [],
   "__v": 0,
   "battingFirst": "Lucknow Super Giants",
   "bowlingFirst": "Delhi Capitals"
 },
 {
   "_id": {
     "$oid": "65f7a6befc9ad86b0332364a"
   },
   "id": "3",
   "team1": "Kolkata Knight Riders",
   "team2": "Sunrisers Hyderabad",
   "toss": "KKR won the toss and elected to field first",
   "team1flag": "https://i.pinimg.com/564x/11/c1/67/11c167cbf1f87346c479fb0cd37c291d.jpg",
   "team2flag": "https://logotyp.us/file/sunrisers-hyderabad.svg",
   "commentary": "The Kolkata pitch has a reputation for being slightly batting-friendly in the initial overs. Both teams will be aiming to capitalize on the early scoring opportunities and adapt their batting strategies accordingly. Analyzing their batting depth and how they handle pressure situations in close chases or while setting a defendable total could be key factors. This match promises to be a close contest that could go down to the wire. The team that executes their plans better in all aspects of the game and handles the pressure of a tight finish will emerge victorious",
   "stadium": "Eden Gardens,Kolkata",
   "totalRunsTeam1": "185/4",
   "totalRunsTeam2": "186/3",
   "result": "KKR won by 7 wickets",
   "battingTeam1": [
     {
       "id": "57",
       "player": "Mayank Agarwal",
       "runs": 37,
       "balls": 33,
       "fours": 4,
       "sixes": 0,
       "strikeRate": 112.12
     },
     {
       "id": "60",
       "player": "Travis Head",
       "runs": 64,
       "balls": 42,
       "fours": 8,
       "sixes": 2,
       "strikeRate": 152.38
     },
     {
       "id": "55",
       "player": "Aiden Markram",
       "runs": 34,
       "balls": 24,
       "fours": 2,
       "sixes": 3,
       "strikeRate": 141.67
     },
     {
       "id": "62",
       "player": "Rahul Tripathi",
       "runs": 6,
       "balls": 9,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 66.67
     },
     {
       "id": "56",
       "player": "Heinrich Klaasen",
       "runs": 34,
       "balls": 10,
       "fours": 6,
       "sixes": 1,
       "strikeRate": 340
     },
     {
       "id": "61",
       "player": "Abdul Samad",
       "runs": 2,
       "balls": 2,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 100
     }
   ],
   "bowlingTeam1": [
     {
       "id": "85",
       "player": "Mitchell Starc",
       "overs": 4,
       "maidens": 0,
       "runs_": 32,
       "wickets": 1,
       "economy": 8
     },
     {
       "id": "87",
       "player": "Chetan Sakariya",
       "overs": 4,
       "maidens": 1,
       "runs_": 36,
       "wickets": 0,
       "economy": 9
     },
     {
       "id": "86",
       "player": "Sunil Narine",
       "overs": 4,
       "maidens": 0,
       "runs_": 42,
       "wickets": 2,
       "economy": 10.5
     },
     {
       "id": "81",
       "player": "Varun Chakravarthy",
       "overs": 4,
       "maidens": 0,
       "runs_": 38,
       "wickets": 1,
       "economy": 9.5
     },
     {
       "id": "77",
       "player": "Suyash Sharma",
       "overs": 2,
       "maidens": 0,
       "runs_": 20,
       "wickets": 0,
       "economy": 10
     },
     {
       "id": "78",
       "player": "Andre Russell",
       "overs": 2,
       "maidens": 0,
       "runs_": 13,
       "wickets": 0,
       "economy": 6.5
     }
   ],
   "extrasTeam1": 8,
   "battingTeam2": [
     {
       "id": "84",
       "player": "Rahmanullah Gurbaz",
       "runs": 4,
       "balls": 3,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 133.33
     },
     {
       "id": "82",
       "player": "Venkatesh Iyer",
       "runs": 77,
       "balls": 58,
       "fours": 7,
       "sixes": 0,
       "strikeRate": 132.76
     },
     {
       "id": "80",
       "player": "Nitish Rana",
       "runs": 0,
       "balls": 4,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     },
     {
       "id": "83",
       "player": "Shreyas Iyer",
       "runs": 22,
       "balls": 13,
       "fours": 3,
       "sixes": 0,
       "strikeRate": 169.23
     },
     {
       "id": "79",
       "player": "Rinku Singh",
       "runs": 80,
       "balls": 41,
       "fours": 5,
       "sixes": 5,
       "strikeRate": 195.12
     }
   ],
   "bowlingTeam2": [
     {
       "id": "58",
       "player": "Bhuvneshwar Kumar",
       "overs": 4,
       "maidens": 0,
       "runs_": 31,
       "wickets": 1,
       "economy": 7.75
     },
     {
       "id": "63",
       "player": "Pat Cummins",
       "overs": 4,
       "maidens": 0,
       "runs_": 38,
       "wickets": 1,
       "economy": 9.5
     },
     {
       "id": "64",
       "player": "T Natarajan",
       "overs": 3.5,
       "maidens": 0,
       "runs_": 37,
       "wickets": 0,
       "economy": 9.65
     },
     {
       "id": "59",
       "player": "Washington Sundar",
       "overs": 3,
       "maidens": 0,
       "runs_": 28,
       "wickets": 0,
       "economy": 9.33
     },
     {
       "id": "65",
       "player": "Mayank Markande",
       "overs": 2,
       "maidens": 0,
       "runs_": 15,
       "wickets": 1,
       "economy": 7.5
     },
     {
       "id": "55",
       "player": "Aiden Markram",
       "overs": 3,
       "maidens": 0,
       "runs_": 35,
       "wickets": 0,
       "economy": 11.66
     }
   ],
   "extrasTeam2": 3,
   "fallOfWicketsTeam1": [],
   "fallOfWicketsTeam2": [],
   "__v": 0,
   "battingFirst": "Sunrisers Hyderabad",
   "bowlingFirst": "Kolkata Knight Riders"
 },
 {
   "_id": {
     "$oid": "65f7a78cfc9ad86b0332364b"
   },
   "id": "4",
   "team1": "Gujarat Titans",
   "team2": "Mumbai Indians",
   "team1flag": "https://i.pinimg.com/564x/24/58/35/245835be9c23261523858a643af3aaf2.jpg",
   "team2flag": "https://logotyp.us/file/mumbai-indians.svg",
   "commentary": "Welcome to the much-anticipated clash between the Gujarat Titans and the Mumbai Indians, happening right here in Ahmedabad! The atmosphere is electric as fans from both sides fill the stadium with their cheers and anticipation. The Ahmedabad pitch is known to be a balanced surface, offering something for both batsmen and bowlers. This promises to be a fascinating contest between two well-matched teams.  Analyzing how each team fares against swing bowling and spin bowling, and how their batting depth handles pressure situations, could provide valuable insights.",
   "toss": " GT won the toss and elected to field first",
   "stadium": "Narendra Modi Stadium,Ahmedabad",
   "totalRunsTeam1": "175/3",
   "totalRunsTeam2": "176/4",
   "result": "GT won by 6 wickets",
   "battingTeam1": [
     {
       "id": "41",
       "player": "Rohit Sharma",
       "runs": 57,
       "balls": 45,
       "fours": 3,
       "sixes": 2,
       "strikeRate": 126.67
     },
     {
       "id": "34",
       "player": "Ishan Kishan",
       "runs": 0,
       "balls": 2,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     },
     {
       "id": "35",
       "player": "Suryakumar Yadav",
       "runs": 83,
       "balls": 49,
       "fours": 7,
       "sixes": 3,
       "strikeRate": 169.39
     },
     {
       "id": "39",
       "player": "Nehal Wadhera",
       "runs": 26,
       "balls": 20,
       "fours": 2,
       "sixes": 1,
       "strikeRate": 130
     },
     {
       "id": "36",
       "player": "Tim David",
       "runs": 5,
       "balls": 4,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 125
     }
   ],
   "bowlingTeam1": [
     {
       "id": "14",
       "player": "Umesh Yadav",
       "overs": 4,
       "maidens": 0,
       "runs_": 30,
       "wickets": 1,
       "economy": 7.5
     },
     {
       "id": "17",
       "player": "Mohit Sharma",
       "overs": 4,
       "maidens": 0,
       "runs_": 39,
       "wickets": 1,
       "economy": 9.75
     },
     {
       "id": "21",
       "player": "Spencer Johnson",
       "overs": 4,
       "maidens": 0,
       "runs_": 33,
       "wickets": 0,
       "economy": 8.25
     },
     {
       "id": "13",
       "player": "Rashid Khan",
       "overs": 4,
       "maidens": 0,
       "runs_": 34,
       "wickets": 1,
       "economy": 8.5
     },
     {
       "id": "20",
       "player": "Noor Ahmad",
       "overs": 4,
       "maidens": 0,
       "runs_": 38,
       "wickets": 0,
       "economy": 9.5
     }
   ],
   "extrasTeam1": 4,
   "battingTeam2": [
     {
       "id": "11",
       "player": "Shubman Gill",
       "runs": 96,
       "balls": 53,
       "fours": 9,
       "sixes": 6,
       "strikeRate": 181.13
     },
     {
       "id": "",
       "player": "Wriddhiman Saha",
       "runs": 1,
       "balls": 7,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 14.29
     },
     {
       "id": "16",
       "player": "Sai Sudharsan",
       "runs": 38,
       "balls": 24,
       "fours": 6,
       "sixes": 1,
       "strikeRate": 158.33
     },
     {
       "id": "15",
       "player": "Vijay Shankar",
       "runs": 21,
       "balls": 25,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 84
     },
     {
       "id": "12",
       "player": "Abhinav Manohar",
       "runs": 11,
       "balls": 10,
       "fours": 0,
       "sixes": 1,
       "strikeRate": 110
     }
   ],
   "bowlingTeam2": [
     {
       "id": "38",
       "player": "Jasprit Bumrah",
       "overs": 4,
       "maidens": 1,
       "runs_": 18,
       "wickets": 1,
       "economy": 4.5
     },
     {
       "id": "40",
       "player": "Jason Behrendorff",
       "overs": 4,
       "maidens": 0,
       "runs_": 26,
       "wickets": 0,
       "economy": 6.5
     },
     {
       "id": "43",
       "player": "Akash Madhwal",
       "overs": 4,
       "maidens": 0,
       "runs_": 27,
       "wickets": 0,
       "economy": 6.75
     },
     {
       "id": "42",
       "player": "Mohammed Nabi",
       "overs": 2,
       "maidens": 0,
       "runs_": 30,
       "wickets": 0,
       "economy": 15
     },
     {
       "id": "33",
       "player": "Hardik Pandya",
       "overs": 1.5,
       "maidens": 0,
       "runs_": 24,
       "wickets": 1,
       "economy": 16
     },
     {
       "id": "37",
       "player": "Piyush Chawla",
       "overs": 4,
       "maidens": 0,
       "runs_": 44,
       "wickets": 1,
       "economy": 11
     }
   ],
   "extrasTeam2": 9,
   "fallOfWicketsTeam1": [],
   "fallOfWicketsTeam2": [],
   "battingFirst": "Mumbai Indians",
   "bowlingFirst": "Gujarat Titans"
 },
 {
   "_id": {
     "$oid": "65f7a81bfc9ad86b0332364c"
   },
   "id": "5",
   "team1": "Royal Challengers Bangalore",
   "team2": "Lucknow Super Giants",
   "team1flag": "https://logotyp.us/file/royal-challengers.svg",
   "team2flag": "https://i.pinimg.com/564x/42/92/9f/42929f323d5f435c7838c8d7ab26f40b.jpg",
   "commentary": "Welcome to the thrilling clash between the Royal Challengers Bangalore (RCB) and the Lucknow Super Giants (LSG), live from the iconic M. Chinnaswamy Stadium in Bangalore! We've witnessed some sensational cricket so far, and today promises to be no different. The Chinnaswamy pitch is well known for its batting-friendly characteristics. Expect to see some explosive batting displays from both teams. Analyzing the batting depth of both RCB and LSG will be interesting. How well they can handle the loss of wickets and keep the scoreboard ticking could be a crucial factor. It will be interesting to see how LSG bounces back after their batting collapse.  Their ability to regroup and adapt their bowling strategies will be crucial.",
   "toss": " LSG won the toss and elected to field first",
   "stadium": "M.Chinnaswamy Stadium,Bangalore",
   "totalRunsTeam1": "187/3",
   "totalRunsTeam2": "170/9",
   "result": "RCB won by 17 runs",
   "battingTeam1": [
     {
       "id": "22",
       "player": "Faf du Plessis",
       "runs": 52,
       "balls": 45,
       "fours": 2,
       "sixes": 2,
       "strikeRate": 115.56
     },
     {
       "id": "23",
       "player": "Virat Kohli",
       "runs": 21,
       "balls": 15,
       "fours": 2,
       "sixes": 1,
       "strikeRate": 140
     },
     {
       "id": "29",
       "player": "Rajat Patidar",
       "runs": 25,
       "balls": 18,
       "fours": 3,
       "sixes": 0,
       "strikeRate": 138.89
     },
     {
       "id": "26",
       "player": "Glenn Maxwell",
       "runs": 50,
       "balls": 29,
       "fours": 6,
       "sixes": 2,
       "strikeRate": 172.41
     },
     {
       "id": "31",
       "player": "Dinesh Karthik",
       "runs": 27,
       "balls": 13,
       "fours": 4,
       "sixes": 1,
       "strikeRate": 207.69
     }
   ],
   "bowlingTeam1": [
     {
       "id": "53",
       "player": "Naveen-ul-Haq",
       "overs": 4,
       "maidens": 0,
       "runs_": 25,
       "wickets": 0,
       "economy": 6.25
     },
     {
       "id": "49",
       "player": "Ravi Bishnoi",
       "overs": 4,
       "maidens": 0,
       "runs_": 25,
       "wickets": 1,
       "economy": 6.25
     },
     {
       "id": "52",
       "player": "Krunal Pandya",
       "overs": 2,
       "maidens": 0,
       "runs_": 23,
       "wickets": 0,
       "economy": 11.5
     },
     {
       "id": "54",
       "player": "Mohsin Khan",
       "overs": 4,
       "maidens": 0,
       "runs_": 36,
       "wickets": 0,
       "economy": 9
     },
     {
       "id": "45",
       "player": "Marcus Stoinis",
       "overs": 2,
       "maidens": 0,
       "runs_": 25,
       "wickets": 0,
       "economy": 12.5
     },
     {
       "id": "51",
       "player": "Shivam Mavi",
       "overs": 4,
       "maidens": 0,
       "runs_": 52,
       "wickets": 2,
       "economy": 13
     }
   ],
   "extrasTeam1": 12,
   "battingTeam2": [
     {
       "id": "44",
       "player": "Quinton de Kock",
       "runs": 75,
       "balls": 45,
       "fours": 5,
       "sixes": 5,
       "strikeRate": 166.67
     },
     {
       "id": "46",
       "player": "KL Rahul",
       "runs": 15,
       "balls": 11,
       "fours": 1,
       "sixes": 1,
       "strikeRate": 136.36
     },
     {
       "id": "47",
       "player": "Devdutt Padikkal",
       "runs": 11,
       "balls": 14,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 78.57
     },
     {
       "id": "50",
       "player": "Nicholas Pooran",
       "runs": 16,
       "balls": 11,
       "fours": 0,
       "sixes": 2,
       "strikeRate": 145.45
     },
     {
       "id": "48",
       "player": "Ayush Badoni",
       "runs": 4,
       "balls": 4,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 100
     },
     {
       "id": "45",
       "player": "Marcus Stoinis",
       "runs": 32,
       "balls": 19,
       "fours": 0,
       "sixes": 3,
       "strikeRate": 168.42
     },
     {
       "id": "52",
       "player": "Krunal Pandya",
       "runs": 1,
       "balls": 3,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 33.33
     },
     {
       "id": "53",
       "player": "Naveen-ul-Haq",
       "runs": 2,
       "balls": 3,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 66.67
     },
     {
       "id": "54",
       "player": "Mohsin Khan",
       "runs": 2,
       "balls": 4,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 50
     },
     {
       "id": "51",
       "player": "Shivam Mavi",
       "runs": 6,
       "balls": 5,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 120
     },
     {
       "id": "49",
       "player": "Ravi Bishnoi",
       "runs": 0,
       "balls": 1,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     }
   ],
   "bowlingTeam2": [
     {
       "id": "24",
       "player": "Mohammed Siraj",
       "overs": 4,
       "maidens": 0,
       "runs_": 29,
       "wickets": 2,
       "economy": 7.25
     },
     {
       "id": "30",
       "player": "Akash Deep",
       "overs": 4,
       "maidens": 0,
       "runs_": 27,
       "wickets": 1,
       "economy": 6.75
     },
     {
       "id": "27",
       "player": "Vijaykumar Vyshak",
       "overs": 4,
       "maidens": 0,
       "runs_": 44,
       "wickets": 1,
       "economy": 11
     },
     {
       "id": "28",
       "player": "Alzari Joseph",
       "overs": 4,
       "maidens": 0,
       "runs_": 31,
       "wickets": 2,
       "economy": 7.75
     },
     {
       "id": "32",
       "player": "Cameron Green",
       "overs": 2,
       "maidens": 0,
       "runs_": 22,
       "wickets": 1,
       "economy": 11
     },
     {
       "id": "26",
       "player": "Glenn Maxwell",
       "overs": 2,
       "maidens": 0,
       "runs_": 15,
       "wickets": 1,
       "economy": 7.5
     }
   ],
   "extrasTeam2": 6,
   "fallOfWicketsTeam1": [],
   "fallOfWicketsTeam2": [],
   "battingFirst": "Royal Challengers Bangalore",
   "bowlingFirst": "Lucknow Super Giants"
 },
 {
   "_id": {
     "$oid": "65f7a849fc9ad86b0332364e"
   },
   "id": "6",
   "team1": "Mumbai Indians",
   "team2": "Chennai Super Kings",
   "team1flag": "https://logotyp.us/file/mumbai-indians.svg",
   "team2flag": "https://i.pinimg.com/564x/ea/40/fd/ea40fd13fa7d46618c5567146f07ba41.jpg",
   "commentary": "Welcome to the blockbuster clash between two titans of the Indian Premier League, the Mumbai Indians and the Chennai Super Kings! The action is unfolding at a fever pitch here at the venue, and what a spectacle it's shaping up to be! The Wankhede pitch is known for being slightly batsman-friendly, offering good opportunities for strokeplay throughout the innings. Both teams will be aiming to capitalize on this and put up impressive scores. Pacers who can take early wickets or bowl economically upfront will be crucial. It will be intriguing to see which team seizes the momentum in this close encounter",
   "toss": " MI won the toss and elected to field first",
   "stadium": "Wankhede Stadium,Mumbai",
   "totalRunsTeam1": "191/4",
   "totalRunsTeam2": "192/3",
   "result": "MI won by 7 wickets",
   "battingTeam1": [
     {
       "id": "1",
       "player": "Rachin Ravindra",
       "runs": 0,
       "balls": 3,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     },
     {
       "id": "2",
       "player": "Ruturaj Gaikwad",
       "runs": 70,
       "balls": 48,
       "fours": 7,
       "sixes": 3,
       "strikeRate": 145.83
     },
     {
       "id": "10",
       "player": "Ajinkya Rahane",
       "runs": 55,
       "balls": 33,
       "fours": 9,
       "sixes": 1,
       "strikeRate": 166.67
     },
     {
       "id": "9",
       "player": "Shivam Dube",
       "runs": 33,
       "balls": 19,
       "fours": 2,
       "sixes": 3,
       "strikeRate": 173.68
     },
     {
       "id": "3",
       "player": "Ravindra Jadeja",
       "runs": 10,
       "balls": 11,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 90.9
     },
     {
       "id": "0",
       "player": "Mahendra Singh Dhoni",
       "runs": 14,
       "balls": 6,
       "fours": 1,
       "sixes": 1,
       "strikeRate": 233.33
     }
   ],
   "bowlingTeam1": [
     {
       "id": "40",
       "player": "Jason Behrendorff",
       "overs": 4,
       "maidens": 0,
       "runs_": 40,
       "wickets": 1,
       "economy": 10
     },
     {
       "id": "38",
       "player": "Jasprit Bumrah",
       "overs": 4,
       "maidens": 0,
       "runs_": 29,
       "wickets": 0,
       "economy": 7.25
     },
     {
       "id": "37",
       "player": "Piyush Chawla",
       "overs": 4,
       "maidens": 0,
       "runs_": 34,
       "wickets": 2,
       "economy": 8.5
     },
     {
       "id": "42",
       "player": "Mohammed Nabi",
       "overs": 3,
       "maidens": 0,
       "runs_": 30,
       "wickets": 0,
       "economy": 8.67
     },
     {
       "id": "33",
       "player": "Hardik Pandya",
       "overs": 3,
       "maidens": 0,
       "runs_": 32,
       "wickets": 0,
       "economy": 8.67
     },
     {
       "id": "43",
       "player": "Akash Madhwal",
       "overs": 2,
       "maidens": 0,
       "runs_": 18,
       "wickets": 0,
       "economy": 9
     }
   ],
   "extrasTeam1": 9,
   "battingTeam2": [
     {
       "id": "41",
       "player": "Rohit Sharma",
       "runs": 50,
       "balls": 31,
       "fours": 15,
       "sixes": 3,
       "strikeRate": 161.29
     },
     {
       "id": "34",
       "player": "Ishan Kishan",
       "runs": 38,
       "balls": 24,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 158.33
     },
     {
       "id": "35",
       "player": "Suryakumar Yadav",
       "runs": 29,
       "balls": 14,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 207.14
     },
     {
       "id": "39",
       "player": "Nehal Wadhera",
       "runs": 54,
       "balls": 42,
       "fours": 6,
       "sixes": 1,
       "strikeRate": 128.57
     },
     {
       "id": "33",
       "player": "Hardik Pandya",
       "runs": 7,
       "balls": 5,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 140
     }
   ],
   "bowlingTeam2": [
     {
       "id": "3",
       "player": "Ravindra Jadeja",
       "overs": 3,
       "maidens": 0,
       "runs_": 32,
       "wickets": 1,
       "economy": 10.67
     },
     {
       "id": "6",
       "player": "Shardul Thakur",
       "overs": 3,
       "maidens": 0,
       "runs_": 28,
       "wickets": 0,
       "economy": 9.33
     },
     {
       "id": "7",
       "player": "Deepak Chahar",
       "overs": 4,
       "maidens": 0,
       "runs_": 25,
       "wickets": 2,
       "economy": 6.25
     },
     {
       "id": "4",
       "player": "Matheesha Pathirana",
       "overs": 2.1,
       "maidens": 0,
       "runs_": 23,
       "wickets": 0,
       "economy": 10.95
     },
     {
       "id": "8",
       "player": "Tushar Deshpande",
       "overs": 4,
       "maidens": 0,
       "runs_": 49,
       "wickets": 1,
       "economy": 12.25
     },
     {
       "id": "5",
       "player": "Moeen Ali",
       "overs": 3,
       "maidens": 0,
       "runs_": 32,
       "wickets": 0,
       "economy": 10.67
     }
   ],
   "extrasTeam2": 14,
   "fallOfWicketsTeam1": [],
   "fallOfWicketsTeam2": [],
   "battingFirst": "Chennai Super Kings",
   "bowlingFirst": "Mumbai Indians"
 },
 {
   "_id": {
     "$oid": "65f7a88afc9ad86b0332364f"
   },
   "id": "7",
   "team1": "Sunrisers Hyderabad",
   "team2": "Gujarat Titans",
   "team1flag": "https://logotyp.us/file/sunrisers-hyderabad.svg",
   "team2flag": "https://i.pinimg.com/564x/24/58/35/245835be9c23261523858a643af3aaf2.jpg",
   "toss": " SRH won the toss and decided to bat first",
   "commentary": "Welcome to what promises to be a captivating clash between the Sunrisers Hyderabad (SRH) and the Gujarat Titans (GT) here at the sun-soaked Rajiv Gandhi International Cricket Stadium in Hyderabad! The Hyderabad pitch has a reputation for being slightly balanced. While batsmen can score freely initially, the pitch can slow down a touch later, potentially assisting spinners. Pacers who can swing the new ball or bowl with good pace will be crucial upfront. The team that handles the pressure better in the final stages and emerges stronger in crucial moments will have a significant advantage.",
   "stadium": "Rajiv Gandhi International Cricket Stadium, Hyderabad",
   "totalRunsTeam1": "197/7",
   "totalRunsTeam2": "193/5",
   "result": "SRH won by 4 runs",
   "battingTeam1": [
     {
       "id": "60",
       "player": "Travis Head",
       "runs": 37,
       "balls": 22,
       "fours": 7,
       "sixes": 0,
       "strikeRate": 168.18
     },
     {
       "id": "57",
       "player": "Mayank Agarwal",
       "runs": 63,
       "balls": 33,
       "fours": 7,
       "sixes": 4,
       "strikeRate": 190.91
     },
     {
       "id": "55",
       "player": "Aiden Markram",
       "runs": 30,
       "balls": 19,
       "fours": 1,
       "sixes": 2,
       "strikeRate": 157.89
     },
     {
       "id": "62",
       "player": "Rahul Tripathi",
       "runs": 20,
       "balls": 13,
       "fours": 2,
       "sixes": 1,
       "strikeRate": 153.85
     },
     {
       "id": "56",
       "player": "Heinrich Klaasen",
       "runs": 0,
       "balls": 1,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     },
     {
       "id": "61",
       "player": "Abdul Samad",
       "runs": 29,
       "balls": 17,
       "fours": 2,
       "sixes": 1,
       "strikeRate": 170.59
     },
     {
       "id": "59",
       "player": "Washington Sundar",
       "runs": 14,
       "balls": 11,
       "fours": 0,
       "sixes": 1,
       "strikeRate": 127.27
     },
     {
       "id": "63",
       "player": "Pat Cummins",
       "runs": 3,
       "balls": 4,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 75
     },
     {
       "id": "58",
       "player": "Bhuvneshwar Kumar",
       "runs": 0,
       "balls": 0,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     }
   ],
   "bowlingTeam1": [
     {
       "id": "15",
       "player": "Vijay Shankar",
       "overs": 2,
       "maidens": 0,
       "runs_": 27,
       "wickets": 0,
       "economy": 13.5
     },
     {
       "id": "21",
       "player": "Spencer Johnson",
       "overs": 4,
       "maidens": 0,
       "runs_": 41,
       "wickets": 1,
       "economy": 10.25
     },
     {
       "id": "17",
       "player": "Mohit Sharma",
       "overs": 3,
       "maidens": 0,
       "runs_": 33,
       "wickets": 2,
       "economy": 11
     },
     {
       "id": "13",
       "player": "Rashid Khan",
       "overs": 4,
       "maidens": 0,
       "runs_": 34,
       "wickets": 2,
       "economy": 8.5
     },
     {
       "id": "20",
       "player": "Noor Ahmad",
       "overs": 3,
       "maidens": 0,
       "runs_": 25,
       "wickets": 1,
       "economy": 8.33
     },
     {
       "id": "14",
       "player": "Umesh Yadav",
       "overs": 4,
       "maidens": 0,
       "runs_": 37,
       "wickets": 1,
       "economy": 9.25
     }
   ],
   "extrasTeam1": 1,
   "battingTeam2": [
     {
       "id": "11",
       "player": "Shubman Gill",
       "runs": 11,
       "balls": 9,
       "fours": 2,
       "sixes": 0,
       "strikeRate": 122.22
     },
     {
       "id": "18",
       "player": "Wriddhiman Saha",
       "runs": 12,
       "balls": 10,
       "fours": 0,
       "sixes": 1,
       "strikeRate": 120
     },
     {
       "id": "16",
       "player": "Sai Sudharsan",
       "runs": 49,
       "balls": 35,
       "fours": 5,
       "sixes": 1,
       "strikeRate": 140
     },
     {
       "id": "15",
       "player": "Vijay Shankar",
       "runs": 9,
       "balls": 8,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 112.5
     },
     {
       "id": "19",
       "player": "David Miller",
       "runs": 79,
       "balls": 44,
       "fours": 6,
       "sixes": 5,
       "strikeRate": 179.55
     },
     {
       "id": "13",
       "player": "Rashid Khan",
       "runs": 19,
       "balls": 13,
       "fours": 1,
       "sixes": 1,
       "strikeRate": 146.15
     },
     {
       "id": "14",
       "player": "Umesh Yadav",
       "runs": 1,
       "balls": 1,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 100
     }
   ],
   "bowlingTeam2": [
     {
       "id": "65",
       "player": "Mayank Markande",
       "overs": 4,
       "maidens": 0,
       "runs_": 37,
       "wickets": 1,
       "economy": 9.25
     },
     {
       "id": "63",
       "player": "Pat Cummins",
       "overs": 4,
       "maidens": 0,
       "runs_": 47,
       "wickets": 1,
       "economy": 11.75
     },
     {
       "id": "64",
       "player": "T Natarajan",
       "overs": 4,
       "maidens": 0,
       "runs_": 47,
       "wickets": 2,
       "economy": 11.75
     },
     {
       "id": "58",
       "player": "Bhuvneshwar Kumar",
       "overs": 3,
       "maidens": 0,
       "runs_": 18,
       "wickets": 0,
       "economy": 6
     },
     {
       "id": "59",
       "player": "Washington Sundar",
       "overs": 4,
       "maidens": 0,
       "runs_": 32,
       "wickets": 1,
       "economy": 8
     },
     {
       "id": "55",
       "player": "Aiden Markram",
       "overs": 1,
       "maidens": 0,
       "runs_": 10,
       "wickets": 0,
       "economy": 10
     }
   ],
   "extrasTeam2": 13,
   "fallOfWicketsTeam1": [],
   "fallOfWicketsTeam2": [],
   "battingFirst": "Sunrisers Hyderabad",
   "bowlingFirst": "Gujarat Titans"
 },
 {
   "_id": {
     "$oid": "65f7a8d9fc9ad86b03323650"
   },
   "id": "8",
   "team1": "Delhi Capitals",
   "team2": "Kolkata Knight Riders",
   "team1flag": "https://logotyp.us/file/delhi-capitals.svg",
   "team2flag": "https://i.pinimg.com/564x/11/c1/67/11c167cbf1f87346c479fb0cd37c291d.jpg",
   "commentary": "Welcome to the exhilarating clash between the Delhi Capitals (DC) and the Kolkata Knight Riders (KKR) here at the iconic Arun Jaitley Stadium in Delhi! The pitch at the Arun Jaitley Stadium has historically favored batsmen, with true bounce and even pace. But as the match progresses, spinners might come into play, adding an extra dimension to the game. Both teams will need their bowlers to be disciplined and accurate, taking advantage of any swing or seam movement on offer. With the excitement levels soaring, it's time for cricket aficionados to settle in and enjoy the spectacle",
   "toss": " KKR won the toss and elected to field first",
   "stadium": "Arun Jaitley Stadium,Delhi",
   "totalRunsTeam1": "177/5",
   "totalRunsTeam2": "159/7",
   "result": "DC won by 18 runs",
   "battingTeam1": [
     {
       "id": "72",
       "player": "David Warner",
       "runs": 35,
       "balls": 29,
       "fours": 6,
       "sixes": 0,
       "strikeRate": 120.69
     },
     {
       "id": "74",
       "player": "Prithvi Shaw",
       "runs": 36,
       "balls": 19,
       "fours": 6,
       "sixes": 1,
       "strikeRate": 189.47
     },
     {
       "id": "71",
       "player": "Mitchell Marsh",
       "runs": 6,
       "balls": 4,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 150
     },
     {
       "id": "66",
       "player": "Rishabh Pant",
       "runs": 50,
       "balls": 32,
       "fours": 4,
       "sixes": 3,
       "strikeRate": 156.25
     },
     {
       "id": "70",
       "player": "Yash Dhull",
       "runs": 24,
       "balls": 23,
       "fours": 3,
       "sixes": 0,
       "strikeRate": 104.35
     },
     {
       "id": "75",
       "player": "Tristian Stubbs",
       "runs": 6,
       "balls": 6,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 100
     },
     {
       "id": "68",
       "player": "Axar Patel",
       "runs": 14,
       "balls": 7,
       "fours": 1,
       "sixes": 1,
       "strikeRate": 200
     }
   ],
   "bowlingTeam1": [
     {
       "id": "85",
       "player": "Mitchell Starc",
       "overs": 4,
       "maidens": 0,
       "runs_": 36,
       "wickets": 0,
       "economy": 9
     },
     {
       "id": "87",
       "player": "Chetan Sakariya",
       "overs": 3,
       "maidens": 0,
       "runs_": 32,
       "wickets": 0,
       "economy": 10.67
     },
     {
       "id": "77",
       "player": "Suyash Sharma",
       "overs": 4,
       "maidens": 0,
       "runs_": 34,
       "wickets": 2,
       "economy": 8.5
     },
     {
       "id": "81",
       "player": "Varun Chakravarthy",
       "overs": 4,
       "maidens": 0,
       "runs_": 20,
       "wickets": 2,
       "economy": 5
     },
     {
       "id": "86",
       "player": "Sunil Narine",
       "overs": 3,
       "maidens": 0,
       "runs_": 30,
       "wickets": 1,
       "economy": 10
     },
     {
       "id": "78",
       "player": "Andre Russell",
       "overs": 2,
       "maidens": 0,
       "runs_": 22,
       "wickets": 0,
       "economy": 11
     }
   ],
   "extrasTeam1": 6,
   "battingTeam2": [
     {
       "id": "82",
       "player": "Venkatesh Iyer",
       "runs": 0,
       "balls": 2,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 0
     },
     {
       "id": "84",
       "player": "Rahmanullah Gurbaz",
       "runs": 8,
       "balls": 14,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 57.14
     },
     {
       "id": "80",
       "player": "Nitish Rana",
       "runs": 7,
       "balls": 10,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 70
     },
     {
       "id": "83",
       "player": "Shreyas Iyer",
       "runs": 13,
       "balls": 19,
       "fours": 2,
       "sixes": 0,
       "strikeRate": 68.42
     },
     {
       "id": "86",
       "player": "Sunil Narine",
       "runs": 20,
       "balls": 15,
       "fours": 1,
       "sixes": 1,
       "strikeRate": 133.33
     },
     {
       "id": "78",
       "player": "Andre Russell",
       "runs": 34,
       "balls": 21,
       "fours": 4,
       "sixes": 2,
       "strikeRate": 161.9
     },
     {
       "id": "79",
       "player": "Rinku Singh",
       "runs": 60,
       "balls": 24,
       "fours": 5,
       "sixes": 6,
       "strikeRate": 250
     },
     {
       "id": "85",
       "player": "Mitchell Starc",
       "runs": 5,
       "balls": 11,
       "fours": 1,
       "sixes": 0,
       "strikeRate": 45.45
     },
     {
       "id": "81",
       "player": "Varun Chakravarthy",
       "runs": 4,
       "balls": 6,
       "fours": 0,
       "sixes": 0,
       "strikeRate": 66.67
     }
   ],
   "bowlingTeam2": [
     {
       "id": "67",
       "player": "Anrich Nortje",
       "overs": 4,
       "maidens": 1,
       "runs_": 22,
       "wickets": 1,
       "economy": 5.5
     },
     {
       "id": "69",
       "player": "Mukesh Kumar",
       "overs": 4,
       "maidens": 0,
       "runs_": 41,
       "wickets": 2,
       "economy": 10.25
     },
     {
       "id": "73",
       "player": "Kuldeep Yadav",
       "overs": 4,
       "maidens": 1,
       "runs_": 23,
       "wickets": 2,
       "economy": 5.75
     },
     {
       "id": "68",
       "player": "Axar Patel",
       "overs": 4,
       "maidens": 0,
       "runs_": 30,
       "wickets": 2,
       "economy": 7.5
     },
     {
       "id": "76",
       "player": "Khaleel Ahmed",
       "overs": 4,
       "maidens": 0,
       "runs_": 40,
       "wickets": 0,
       "economy": 10
     }
   ],
   "extrasTeam2": 8,
   "fallOfWicketsTeam1": [],
   "fallOfWicketsTeam2": [],
   "battingFirst": "Delhi Capitals",
   "bowlingFirst": "Kolkata Knight Riders"
 }]
 export default data;